import { MutationTree } from 'vuex';
import { CheckinPartnerOcrInterface } from 'src/interfaces/CheckinPartnerOcrInterface';
import { OcrDocumentStateInterface } from '.';

const mutation: MutationTree<OcrDocumentStateInterface> = {

  SET_DOCUMENT_DATA(state: OcrDocumentStateInterface,
    checkinPartnerOcr: CheckinPartnerOcrInterface) {
    if (checkinPartnerOcr.birthdate) {
      checkinPartnerOcr.birthdate = new Date(checkinPartnerOcr.birthdate);
    }
    if (checkinPartnerOcr.documentExpeditionDate) {
      checkinPartnerOcr.documentExpeditionDate = new Date(checkinPartnerOcr.documentExpeditionDate);
    }
    state.documentData = checkinPartnerOcr;
  },

};

export default mutation;
